import React, { Component } from 'react';
import './index.css';
import { translate } from '../../util/i18n';

const getPath = (link) => {
    return translate(`page.${link.id}.path`);
}

// const getTitle = (link) => {
//     return translate(`page.${link.id}.title`);
// }

const getLabel = (link) => {
    return translate(`component.navbar.${link.id}.label`);
}

const links = [
    {
        // name: "Inicio",
        id: "home",
        // path: "/",
        default: true,
    },
    {
        // name: "Equipo",
        id: "team",
        // path: "/equipo",
    },
    {
        // name: "Trabajos",
        id: "portfolio",
        // path: "/trabajos",
    },
    {
        // name: "Contacto",
        id: "contact",
        // path: "/contacto",
    },
    {
        // name: "Contacto",
        id: "privacy",
        // path: "/contacto",
        hide: true
    }
]

class NavbarLink extends Component {

    handleClick = () => {

        // print state of all inputs by the name 'tabs'
        document.querySelectorAll('input[name="tabs"]').forEach((input) => {
            // console.log(input.id + " " + input.checked);
        });

        if (this.props.onClick) this.props.onClick();
    }

    render() {
        return (
            <>
                <input className="flex-1 peer-checked:text-shark" type='radio' name="tabs" id={this.props.id} active={(!!this.props.active || false).toString()} onClick={this.handleClick} />
                <label className={`rounded-full py-[10px] px-[20px] cursor-pointer transition-colors flex-1 text-center z-10 w-[110px]`} htmlFor={this.props.id} >
                    {this.props.label}
                </label>
            </>
        )
    }

}

const getOffset = (link) => {
    if (link.hide) return 2147483647;
    return links.indexOf(link) * 100;
}

export default class StickyNavbar extends Component {


    constructor(props) {
        super(props);
        let link = links.find(link => getPath(link) === window.location.pathname);
        this.state = {
            activeTab: link ? link.id : links[0].id,
            gliderOffset: getOffset(link),
            end: false,
            mobileMenuOpen: false
        }
        // document.title = getTitle(link);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            let link = links.find(link => getPath(link) === this.props.location.pathname);
            if (!link) {
                link = links[0];
            }
            // document.title = getTitle(link);
            let activeTab = document.getElementById(link.id);
            if (activeTab)
                activeTab.checked = true;
            else {
                // unchecked all inputs
                document.querySelectorAll('input[name="tabs"]').forEach((input) => {
                    input.checked = false;
                });
            }
            this.setState({
                activeTab: link.id,
                gliderOffset: getOffset(link)
            });
            window.scrollTo(0, 0);
            // document.title = getTitle(link);
        }
    }

    componentDidMount() {
        let activeTab = document.getElementById(this.state.activeTab);
        if (activeTab)
            document.getElementById(this.state.activeTab).checked = true;

        // Add listener to check if the bottom of the page is reached
        window.addEventListener('scroll', () => {
            if (window.scrollY + window.innerHeight === document.body.scrollHeight) {
                if (!this.state.end) {
                    this.setState({
                        end: true
                    });
                }
            } else {
                if (this.state.end) {
                    this.setState({
                        end: false
                    });
                }
            }
        });
    }

    navigate = (link) => {
        this.props.navigate(link);
    }

    handleClick = (link, offset) => {
        this.setState({
            gliderOffset: offset * 100
        });
        const path = getPath(link);
        this.navigate(path);
    }

    render() {
        return (
            <div>
                <div className={`hidden md:block -translate-x-1/2 transition-all m-0 p-0 fixed top-[100%] left-[50%] z-10 -translate-y-[100px]`}>
                    <nav className='bg-shark/25 rounded-full m-0 p-0 shadow-sm backdrop-blur border border-shark-200/30'>
                        <div className='flex justify-between p-[5px]'>
                            {links.filter(link => !link.hide).map((link, index) => {
                                return (
                                    <NavbarLink key={index} id={link.id} label={getLabel(link)} active={(!!link.default || false).toString()} onClick={() => this.handleClick(link, index)} />
                                )
                            })}
                            <span id="nav-glider" className={`glider w-[110px]`} style={{ transform: "translateX(" + this.state.gliderOffset + "%)" }}></span>
                        </div>
                    </nav>
                </div>
                <div
                    className="fixed md:hidden bottom-0 right-0 z-40"
                    onClick={() => this.setState({ mobileMenuOpen: !this.state.mobileMenuOpen })} 
                >
                    <button className="rounded-full border border-shark-600/50 bg-shark/50 backdrop-blur text-beige aspect-square mb-8 mr-8 h-[60px] w-[60px] p-[10px]">
                        <svg 
                        className="w-[40px] h-[40px] aspect-square" 
                        fill="none" 
                        stroke="currentColor" 
                        viewBox="0 0 24 24" 
                        xmlns="http://www.w3.org/2000/svg">
                            {
                                this.state.mobileMenuOpen ? 
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path> : 
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path>
                            }
                        </svg>
                    </button>
                </div>
                {this.state.mobileMenuOpen && 
                    <div className="fixed md:hidden h-full w-full bg-shark/75 backdrop-blur-sm z-40">
                        <div className="p-10 w-full h-full flex flex-col justify-end">
                            <div className="grow"/>
                            <div className="flex flex-col justify-end text-end text-xl divide-y divide-beige/50 mb-16">
                                {links.filter(link => !link.hide).map((link, index) => {
                                    const path = getPath(link);
                                    const label = getLabel(link);
                                    return (
                                        <button 
                                            key={index} 
                                            href={path} 
                                            className={"p-5 " + (path === window.location.pathname ? "text-shark bg-beige" : "text-beige")} 
                                            onClick={() => {
                                                this.setState({ mobileMenuOpen: false });
                                                this.navigate(path);
                                            }}>
                                                {label}
                                        </button>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                }
                
            </div>
        )
    }

}