import React, { } from 'react';
import './elegir.css';
import Container from '../container';
import Section from '../section';
import { translate } from '../../util/i18n';
import { Link } from 'react-router-dom';

export function Texto(props) {
    return (
        <>
            <Section className="px-5 xl:px-0">
                <Container className="border-x border-beige/50 h-16"/>
            </Section>
            <Section className="px-5 xl:px-0 border-t border-beige/50">
                <Container className="border-x border-beige/50">
                    {/* sm:w-full laptop:w-[100%] desktop5:w-[100%] */}
                    <div className="elegirnos w-full bg-shark h-max" >
                        <p 
                            className='texto-pregunta text-beige font-bold text-xl tracking-[3px] text-center pt-8 pb-4 sm:text-left sm:pl-10'
                            dangerouslySetInnerHTML={{__html: translate('page.home.reasons.section.title')}}
                        >
                            
                        </p>
                        <p className='respuesta-pregunta max-w-xl text-beige text-xl text-center ml-4 sm:text-left sm:ml-0 sm:pl-10 md:mb-10'>
                            {translate('page.home.reasons.section.description')}
                        </p>
                    </div>
                </Container>
            </Section>
            <div className="px-5 xl:px-0">
                <Contenedor/>
            </div>
            <Section className="px-5 xl:px-0 border-y border-beige/50">
                <Container className="border-x border-beige/50 h-16"/>
            </Section>
        </>
    )
}

export function Contenedor(props) {
    return (
        // <div className='cont-item grid justify-center laptop:flex laptop:space-x-[15%]'>
        <Section>
            <Container className="border-x border-beige/50">
                <div className="flex flex-col justify-center items-start lg:flex-row divide-y divide-beige/50 lg:divide-y-0 lg:gap-4 xl:gap-4 2xl:gap-10 pt-5 pb-2 md:pb-10">
                    <CuadroElegir nombre="page.home.reasons.reason1.title" desc="page.home.reasons.reason1.description" simbolo="</>" />
                    <CuadroElegir nombre="page.home.reasons.reason2.title" desc="page.home.reasons.reason2.description" simbolo="</>" />
                    <CuadroElegir nombre="page.home.reasons.reason3.title" desc="page.home.reasons.reason3.description" simbolo="</>" />
                </div>
            </Container>
        </Section>
        // </div>
    )
}
export function CuadroElegir(props) {
    return (
        <div className='flex justify-center w-full lg:max-w-[280px] 2xl:max-w-[300px]'>
            <div className="flex items-center justify-center lg:max-w-[280px] 2xl:max-w-[300px]">
                <div className="hidden lg:flex flex-col">
                    <div className='cuadro-item text-center border-solid border-beige/50 border rounded-3xl lg:aspect-square flex flex-col'>
                        <div className='px-5 py-3 border-b border-beige/50'>
                            <p className='titulo-item font-bold text-marino text-[25px] text-center justify-center'>
                                {translate(props.nombre)}
                            </p>
                        </div>
                        <div className="grow flex items-center justify-center w-full p-5">
                            <p className='desc-item text-beige max-w-96 justify-center text-[20px] w-full'>{translate(props.desc)}</p>
                        </div>
                        <div>
                            <Boton />
                        </div>
                        <p className='simbolo text-beige text-right text-3xl pr-8 pb-4'>{props.simbolo}</p>
                    </div>
                </div>
            </div>
            {/* movil */}
            <div className="inline lg:hidden w-full">
                <div className="flex flex-col items-center justify-center w-full p-4">
                    <p className='text-marino font-bold text-center'>{translate(props.nombre)}</p>
                    <p className="text-center mb-3">{translate(props.desc)}</p>
                    {/* <p className='text-beige text-right text-3xl'>{props.simbolo}</p> */}
                    <Boton />
                </div>
            </div>
        </div>

    )
}

export function Boton(props) {
    return (
    <Link to={translate('page.portfolio.path')} rel="noopener noreferrer">
        <button className="btn border-solid border px-4 lg:px-8 lg:py-1 rounded-3xl hover:bg-beige/50 text-beige text-lg">
            {translate("component.button.viewMore.label")}
        </button>
    </Link>

    );

}