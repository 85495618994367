import React, { Component} from "react";
import { ExternalIcon } from "../../components/icons";
import ContactSection from "../../components/contactSection";
import Container from "../../components/container";
import Section from "../../components/section";
import SiteHeader from "../../components/siteHeader";
import { translate } from "../../util/i18n";
import { TECarousel, TECarouselItem } from "tw-elements-react";
import MetadataHelmet from "../../components/metadataHelmet";

const highlights = [
    "cealva",
    "patricia"
]

const projects = [
    {
        id: "cealva", // title, year, description, url
        categories: ["general.project.type.web", "general.project.type.seo", "general.project.type.uxui"],
        img: "proyecto2-large.png",
        imgMobile: "proyecto2-mobile.png",
    },
    {
        id: "patricia", // title, year, description, url
        categories: ["general.project.type.web", "general.project.type.seo", "general.project.type.uxui",],
        img: "proyecto1-large.png", //proyecto1-large.png
        imgMobile: "proyecto1-mobile.png", 
    }
];

// Calculate the categories from the projects
const categories = projects.reduce((acc, project) => {
    project.categories.forEach(category => {
        if (!acc.includes(category)) {
            acc.push(category);
        }
    });
    return acc;
}, []);


const activefilterclass = "lg:basis-auto md:basis-11/12 flex justify-center items-center border rounded-full px-5 bg-beige-50 text-shark transition duration-300 cursor-pointer select-none";
const inactivefilterclass ="lg:basis-auto md:basis-11/12 flex justify-center items-center border rounded-full px-5 text-beige hover:bg-beige-50 hover:text-shark transition duration-300 cursor-pointer select-none";

export class PortfolioCategories extends Component {
   
    constructor(props){
        super(props);
        this.state = {
            activeID: null,
        }
    }

    clickFilter (filter){
        if(filterProyects(filter).length === 0) return
        if (filter === this.state.activeID) {
            this.setState({
                activeID:null
            });
            document.getElementById("highlight-section").className="transition-all duration-300 origin-top";
            this.props.OnFilterSelect(null);
          } else {
            this.setState({
                activeID:filter
            });
            document.getElementById("highlight-section").className="transition-all duration-300 origin-top scale-y-0 h-0";
            this.props.OnFilterSelect(filter);
        } 
    }

    render() {
        return (
            <div className="w-full pt-4 lg:pt-0 md:px-0 border-x border-beige/50 pb-4 md:pb-10">
                <div className="w-full flex flex-col md:flex-row justify-around space-y-4 md:space-y-0">
                    {categories.map((category, index) => {
                        let translated = translate(category);
                        return (
                            <div key={index} className="flex flex-row w-full ">
                                <div className="lg:basis-auto md:basis-1/12 grow flex flex-col justify-center">
                                    <div className="h-[1px] bg-beige/50" />
                                </div>
                                <div id={translated} onClick={() => this.clickFilter(translated)} className={this.state.activeID === translated ? activefilterclass : inactivefilterclass}>
                                    <p className="w-full text-center md:text-sm lg:text-base">{translated}</p>
                                </div>
                                <div className="lg:basis-auto md:basis-1/12 grow flex flex-col justify-center">
                                    <div className="h-[1px] bg-beige/50" />
                                </div>
                            </div>
                        )
                    })
                    }
                </div>
            </div>
        )
    }

}

export class ProjectSection extends Component {

    render() {
        const { proyecto } = this.props;
        return (
            <>
                {/* <Section className="bg-beige text-shark flex-col">
                    <Container>
                        <div className="border-x border-shark">
                            <div className="p-6 flex flex-col md:flex-row">
                                <div>
                                    <h1 className="text-2xl pb-2 flex flex-row flex-wrap space-x-4">
                                        <p>
                                            <span className="font-[500] pr-2">{proyecto.title} - <span className="font-[200] italic">{proyecto.year}</span></span>
                                        </p>
                                    </h1>
                                    <div>
                                        <div className="flex flex-row space-x-2">
                                            {proyecto.categories.map((category, index) => {
                                                return (
                                                    <span key={index} className="bg-shark text-beige px-3 rounded-full font-extralight text-sm">{translate(category)}</span>
                                                )
                                            })
                                            }
                                        </div>
                                    </div>
                                </div>

                                <span className="grow"></span>
                                <div className="pt-5 md:pt-0 flex items-center">
                                    <a href={proyecto.url} target="_blank" rel="noreferrer">
                                        <button className="border border-shark text-shark px-3 py-1 text-lg hover:bg-beige-200 flex flex-row items-center">
                                            {translate('component.button.visitSite.label')} <ExternalIcon className="h-4 ml-2" />
                                        </button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </Container>

                </Section> */}
                <Section className="bg-beige text-shark border-t border-shark">
                    <Container>
                        <div key={proyecto.key} className="relative border-x  border-shark" id={proyecto.id}>
                            <img src={proyecto.img} alt={proyecto.imgAlt} className="w-full" />
                            <div className="hidden lg:inline absolute lg:max-w-48 2xl:max-w-60 right-0 bottom-0 xl:-right-10 xl:-bottom-10">
                                <img src={proyecto.imgMobile} alt={proyecto.imgAlt} className="w-full" />
                            </div>
                        </div>
                    </Container>
                </Section>
            </>
        );
    }
}

const filterProyects = (filter) => {
    const unfilteredproyects = getProjects();
    if(filter){
        const filteredproyects = unfilteredproyects.filter(project => {
            return project.categories.some(category => translate(category) === filter);
        });

        return filteredproyects;
    }else{
        return unfilteredproyects
    }
}

const getProject = (id) => {
    let project = projects.find(project => project.id === id);
    project.title = translate(`page.portfolio.${id}.title`);
    project.year = translate(`page.portfolio.${id}.year`);
    project.url = translate(`page.portfolio.${id}.url`);
    project.description = translate(`page.portfolio.${id}.description`);
    project.imgAlt = translate(`page.portfolio.${id}.imgAlt`);
    return project;
}

const getHighlights = () => {
    return highlights
        .map(highlight => {
            return getProject(highlight);
        })
        .filter(project => project !== undefined);
};

const getProjects = () => {
    return projects
        .map(project => {
            return getProject(project.id);
        })
        .filter(project => project !== undefined);
};

// class HighlightSection extends Component {
//     render() {
//         return (
//             <>
//                 {this.props.separator && <Section className="bg-beige text-shark border-b border-shark">
//                     <Container>
//                         <div className="border-x border-shark h-5 lg:h-16" />
//                     </Container>
//                 </Section>}
//                 <ProjectSection proyecto={this.props.project} />

//             </>
//         );
//     }
// }

class PortfolioList extends Component {
    render() {
        var mode = !!this.props.filter;
        var style = {
            border: mode ? "border-shark" : "border-beige/50",
            bg: mode ? "bg-beige" : "bg-shark",
            text: mode ? "text-shark" : "text-beige"
        }
        return (
            <div className={"w-full border-t flex justify-center flex-col " + style.bg + " " + style.border}>
                {filterProyects(this.props.filter).map((cliente, index) =>
                    <div key={index} className={"border-b "+ style.border +" flex justify-center lg:px-5"}>
                        <Container className="p-5">
                            <div className="flex flex-col lg:flex-row space-y-3 lg:space-y-0">
                                <span className="text-lg font-bold text-cabaret lg:grow">{translate(cliente.title)}: <span className={"font-light italic "+ style.text}>{translate(cliente.description)}</span></span>
                                {/* <span className="hidden lg:inline grow" /> */}
                                <div className="lg:ml-5 h-full flex items-center">
                                    <a href={cliente.url} target="_blank" rel="noreferrer" className="inline-block" >
                                        <button className="border border-marino-500/50 text-marino-500 px-3 py-1 w-36 hover:bg-beige hover:text-marino-800 flex flex-row items-center justify-center transition">
                                            {translate('component.button.visitSite.label')} <ExternalIcon className="h-4 ml-2" />
                                        </button>
                                    </a>
                                </div>

                            </div>
                        </Container>
                    </div>
                )}
            </div>
        )
    }
}

export default class PortfolioPage extends Component {
    constructor(props){
        super(props);
        this.state = {
            highlight: highlights[0],
        }
    }

    handleSlide = () => {
        document.getElementById("highlight-header").className = "transition-all opacity-0";
        document.getElementById("highlight-description").classList.remove("opacity-100");        
        document.getElementById("highlight-description").classList.add("opacity-0");        
    }

    handleSlid = () => {
        this.setState({highlight: document.getElementsByClassName("!z-10")[0].id});
        document.getElementById("highlight-header").className = "transition-all opacity-100";
        document.getElementById("highlight-description").classList.add("opacity-100");
        document.getElementById("highlight-description").classList.remove("opacity-0");
    }

    render() {
        let currentHighlight = getProject(this.state.highlight);

        return (
            <div>
                <MetadataHelmet pageName="portfolio" />
                <SiteHeader />
                <Section>
                    <Container>
                        <div className=" border-x-[1px] border-t-[1px] rounded-t-lg lg:rounded-t-[20px] border-beige/50 flex flex-col p-3 lg:p-10">
                            <div id="title" className="flex justify-center mb-6">
                                <h1 className="text-cabaret text-3xl font-bold tracking-[5px]">{translate('page.portfolio.header.title')}</h1>
                            </div>
                            <div id="description" className="flex flex-col items-center justify-center text-center text-xl">
                                <p dangerouslySetInnerHTML={{ __html: translate('page.portfolio.header.subtitle.1') }}></p>
                                <p dangerouslySetInnerHTML={{ __html: translate('page.portfolio.header.subtitle.2') }}></p>
                            </div>
                        </div>
                        <PortfolioCategories OnFilterSelect={(filter) => {this.setState({filter: filter})}} />
                    </Container>
                </Section>
                {/* <Section className="bg-beige text-shark border-b border-shark">
                    <CarouselPortfolio/>
                </Section> */}
                {/* <Section className="bg-beige text-shark border-b border-shark">
                    <Container>
                        <div className="border-x border-shark h-5 lg:h-16" />
                    </Container>
                </Section> */}
                <div id="highlight-section">
                    <Section className="bg-beige text-shark flex-col">
                        <Container>
                            <div className="border-x border-shark">
                                <div className="p-6 flex flex-col md:flex-row">
                                    <div className="" id="highlight-header">
                                        <h1 className="text-2xl pb-2 flex flex-row flex-wrap space-x-4">
                                            <p id="cabeceraHighlight">
                                                <span className="font-[500] pr-2 transition-opacity">{currentHighlight.title} - <span className="font-[200] italic">{currentHighlight.year}</span></span>
                                            </p>
                                        </h1>
                                        <div>
                                            <div className="flex flex-row space-x-2" id="categoriaProyecto">
                                                {currentHighlight.categories.map((category, index) => {
                                                    return (
                                                        <span key={index} className="bg-shark text-beige px-3 rounded-full font-extralight text-sm">{translate(category)}</span>
                                                    )
                                                })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <span className="grow"></span>
                                    <div className="pt-5 md:pt-0 flex items-center">
                                        <a href={currentHighlight.url} target="_blank" rel="noreferrer" id="highlightURL">
                                            <button className="border border-shark text-shark px-3 py-1 text-lg hover:bg-beige-200 flex flex-row items-center">
                                                {translate('component.button.visitSite.label')} <ExternalIcon className="h-4 ml-2" />
                                            </button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </Container>
                    </Section>
                    <Section className="bg-beige">
                        <TECarousel showControls showIndicators crossfade onSlid={this.handleSlid} onSlide={this.handleSlide} theme={{ indicatorsWrapper: wrapperClasses, carouselWrapper: "relative w-full", }} prevBtnIcon={prevBtn} nextBtnIcon={nxtBtn}>
                            {getHighlights().map((highlight, index) => {
                                var slideID = index + 1;
                                var slideClass = "float-left -mr-[100%] hidden w-full !transform-none opacity-0 transition-all duration-[550ms] ease-in-out motion-reduce:transition-none";
                                return (
                                    <TECarouselItem itemID={slideID}
                                        className={slideClass}
                                        id={highlight.id}
                                        key={index}
                                    >
                                        <ProjectSection proyecto={highlight} key={index} />
                                    </TECarouselItem>
                                );
                            })}
                        </TECarousel>
                    </Section>
                    <Section className="bg-beige text-shark border-t border-shark">
                        <Container>
                            <div className="border-x border-shark p-5 md:p-11 md:px-16">
                                <p id="highlight-description" className="text-xl text-justify transition-all">{currentHighlight.description}</p>
                            </div>
                        </Container>
                    </Section>
                </div>
                <Section className="bg-shark text-beige border-t border-beige/50 flex-col">
                    <Container>
                        <div className="border-x border-beige/50 h-full p-8 flex justify-center">
                            <p className="font-bold tracking-[3px] text-center text-xl" dangerouslySetInnerHTML={{ __html: translate(this.state.filter ? 'page.portfolio.customers.title.filter' : 'page.portfolio.customers.title',[this.state.filter]) }} />
                        </div>
                    </Container>
                    <PortfolioList mode="light" filter={this.state.filter} />
                </Section>
                <ContactSection />
            </div>
        )
    }

}

const prevBtn = <>
    <span className="inline-block text-shark h-8 w-8 [&>svg]:h-8">
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 19.5L8.25 12l7.5-7.5"
            />
        </svg>
    </span>
    <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
        Previous
    </span>
</>;

const nxtBtn = <>
    <span className="inline-block text-shark h-8 w-8 [&>svg]:h-8">
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M8.25 4.5l7.5 7.5-7.5 7.5"
            />
        </svg>
    </span>
    <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
        Next
    </span>
</>;

const wrapperClasses = "absolute bottom-0 left-0 right-0 z-1 mx-[15%] mb-4 flex list-none justify-center p-0"