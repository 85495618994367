const availableLangs = ['es', 'en'];

// load files
const translations = {};
availableLangs.forEach(lang => {
    translations[lang] = require(`./${lang}.json`);
});

// console.log(translations);

export const isSupportedLang = lang => availableLangs.includes(lang);

export const storeCurrentLang = lang => {
    localStorage.setItem('lang', lang);
}

export const inferCurrentLang = () => {
    // const lang = navigator.language.split('-')[0];
    const lang = ""; // Desactivar detección automática de idioma
    if (availableLangs.includes(lang)) {
        return lang;
    }
    return availableLangs[0];
}

export const getCurrentLang = () => {
    const lang = localStorage.getItem('lang');
    if (lang && availableLangs.includes(lang)) {
        return lang;
    }
    return inferCurrentLang();
}

export const translate = (key, args = []) => {
    const lang = getCurrentLang();
    let translation = translations[lang][key] || key;
    if (args) {
        Object.keys(args).forEach(arg => {
            translation = translation.replace(`{${arg}}`, args[arg]);
        });
    }
    return translation;
}
