import React, { Component } from "react";
import "./index.css";
import ContactSection from "../../components/contactSection";
import SiteHeader from "../../components/siteHeader";
import { translate } from "../../util/i18n";
import MetadataHelmet from "../../components/metadataHelmet";

class TeamHeader extends Component {

    render() {
        return (
            <SiteHeader />
        )
    }

}

class TeamPageSection extends Component {

    render() {
        if (this.props.type === "light") {
            return (
                <section className="px-5 xl:px-0 border-b-[1px] border-shark/50 flex justify-center items-center bg-beige">
                    {this.props.children}
                </section>
            )
        } else {
            return (
                <section className="px-5 xl:px-0 border-b-[1px] border-beige/50 flex justify-center items-center bg-shark">
                    {this.props.children}
                </section>
            )
        }
    }
}

class BorderedContainer extends Component {

    render() {
        if (this.props.type === "light") {
            return (
                <div className="w-full max-w-[1510px] xl:w-[70%] lg:min-w-[600px] border-x-[1px] border-shark/50 flex flex-row justify-center text-shark">
                    {this.props.children}
                </div>
            )
        } else {
            return (
                <div className="w-full max-w-[1510px] xl:w-[70%] lg:min-w-[600px] border-x-[1px] border-beige/50 flex flex-row justify-center text-beige">
                    {this.props.children}
                </div>
            )
        }
    }
}

class TeamMemberCard extends Component {

    render() {
        const { id } = this.props;
        const name = translate(`component.team.member.${id}.name`);
        const role = translate(`component.team.member.${id}.role`);
        const email = translate(`component.team.member.${id}.email`);
        const photo = translate(`component.team.member.${id}.photo`);
        const linkedin = translate(`component.team.member.${id}.linkedin`);
        return (
            <div className="flex flex-col items-center justify-center space-y-2">
                <div>
                    <div className="aspect-square w-[200px] lg:w-[300px] rounded-full overflow-hidden whitespace-nowrap">
                        <img src={photo} alt={role}
                            className="object-cover w-full h-full"
                        />
                    </div>
                </div>
                <div className="flex flex-col items-center justify-center text-beige">
                    <div className="flex flex-row items-center content-center">
                        <h1 className="text-2xl font-bold text-center">{name}</h1>
                        <a href={linkedin} className="pl-2" target="_blank" rel="noopener noreferrer">
                            <img className="aspect-square max-w-[30px]" src="linkedin-icon.png" alt="linkedin"/>
                        </a>
                    </div>
                    <p className="text-xl text-center">{translate(role)}</p>
                    <p className="italic font-extralight text-xl">{email}</p>
                </div>
            </div>
        );
    }


}

const members = [
    {
        id: "abraham",
        // name: "Abraham Alvarado",
        // email: "abraham@navvi.studio",
        // linkedin: "https://www.linkedin.com/in/abraham-alvarado-1a6b7a1b1/",
        // picture: "abraham.txt"
    },
    {
        id: "malu",
        // name: "Malu Díaz",
        // email: "maria@navvi.studio",
        // linkedin: "https://www.linkedin.com/in/malu-diaz/",
        // picture: "malu.txt"
    },
    {
        id: "daniel",
        // name: "Daniel Velásquez",
        // email: "daniel@navvi.studio",
        // linkedin: "https://www.linkedin.com/in/abraham-alvarado-1a6b7a1b1/",
        // picture: "daniel.txt"
    },
    {
        id: "julio",
        // name: "Julio Martinez",
        // email: "julio@navvi.studio",
        // linkedin: "https://www.linkedin.com/in/malu-diaz/",
        // picture: "julio.txt"
    },
    {
        id: "jorge",
        // name: "Jorge Berrezueta",
        // email: "jorge@navvi.studio",
        // linkedin: "https://www.linkedin.com/in/abraham-alvarado-1a6b7a1b1/",
        // picture: "jorge.txt"
    },
    {
        id: "gustavo",
        // name: "Gustavo Lemos",
        // email: "gustavo@navvi.studio",
        // linkedin: "https://www.linkedin.com/in/malu-diaz/",
        // picture: "gustavo.txt"
    },

]

export default class TeamPage extends Component {

    render() {
        return (
            <div>
                <MetadataHelmet pageName="team">
                    <meta name="keywords" content={translate('page.team.header.keywords')}></meta>
                </MetadataHelmet>
                <TeamPageSection>
                    <BorderedContainer>
                        <TeamHeader />
                    </BorderedContainer>
                </TeamPageSection>
                <section className="px-0 border-b-[1px] border-beige/50 flex bg-shark">
                    <div id="left-spacer" className="w-[22px] sm:w-[21px] lg:grow min-h-full"></div>
                    <BorderedContainer>
                        <div className="p-5 md:py-14 flex flex-col">
                            <div id="title" className="flex justify-center mb-6">
                                <h1 className="text-cabaret text-3xl font-bold tracking-[5px]">{translate('page.team.header.title')}</h1>
                            </div>
                            <div id="description" className="flex flex-col items-center justify-center text-center text-xl">
                                <p dangerouslySetInnerHTML={{__html: translate('page.team.header.subtitle.1')}}></p>
                                <p dangerouslySetInnerHTML={{__html: translate('page.team.header.subtitle.2')}}></p>
                            </div>
                        </div>
                    </BorderedContainer>
                    <div id="right-spacer" className="w-[22px] sm:w-[21px] md:grow min-h-full"></div>
                </section>
                <TeamPageSection>
                    <BorderedContainer>
                        <div className="p-5 md:grid md:grid-cols-2 lg:grid-cols-3">
                            {members.map((member, index) => {
                                return (
                                    <div key={index} className="p-3">
                                        <TeamMemberCard
                                            id={member.id}
                                            // name={member.name}
                                            // role={member.role}
                                            // email={member.email}
                                            // picture={member.picture}
                                        />
                                    </div>
                                )
                            })}
                        </div>
                    </BorderedContainer>
                </TeamPageSection>
                <ContactSection mode="light" />
            </div>
        );
    }

}