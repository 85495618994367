import React, { Component } from "react";
import { Link } from "react-router-dom";
import './index.css';
import { translate } from "../../util/i18n";

import Container from '../container';

export default class SiteFooter extends Component {
    render() {
        return (
            <div className="pt-5 md:pt-20 lg:pt-14 pb-10 md:pb-32 lg:pb-20 flex items-center justify-center bg-shark text-beige">
                <Container>
                    <div className="flex flex-col items-center lg:items-center lg:flex-row justify-between space-y-4 lg:space-y-0 [&>*]:basis-1/3">
                        <div className="contacto-footer text-center lg:text-left text-lg">
                            <p className="font-bold">{translate('component.footer.contact.title')}</p>
                            <p className='text-marino'><a href={`mailto:${translate('component.footer.contact.email')}`}>{translate('component.footer.contact.email')}</a></p>
                            <p>{translate('component.footer.contact.phone')}</p>
                        </div>
                        <div className="flex h-full items-center justify-center">
                            <img className="w-[217px]" src="navvi_dark.svg" alt="navvi"></img>
                        </div>
                        <div className="rrss-footer h-full flex flex-col items-end">
                            <div className="flex flex-row h-full items-center space-x-3">
                                <a href="https://www.instagram.com/navvi.studio/" target="noopener noreferrer"><img width={35} src='ig-icon.png' alt="Instagram"></img></a>
                                {/* <a href="https://www.tiktok.com/@navvi.studio" target="noopener noreferrer"><img width={35} src='tiktok-icon.png' alt="Tik Tok"></img></a> */}
                            </div>
                        </div>
                    </div>
                    <div className="w-full mt-6 text-center lg:text-left font-bold">
                        <Link to={translate('page.privacy.path')}>{translate('component.footer.privacy')}</Link>
                    </div>
                </Container>
            </div>
        )
    }
}