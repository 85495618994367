import { Component } from "react";
import Section from "../../components/section";
import Container from "../../components/container";
import SiteHeader from "../../components/siteHeader";
import { translate } from "../../util/i18n";
import MetadataHelmet from "../../components/metadataHelmet";

export default class PrivacyPolicyPage extends Component {

    render() {
        return (
            <div>
                <MetadataHelmet pageName="privacy" />
                <Section className="bg-shark text-beige">
                    <SiteHeader />
                </Section>
                <Section className="bg-beige text-shark">
                    <Container className="py-6 lg:py-20">
                        <h1 className="font-bold text-3xl" dangerouslySetInnerHTML={{ __html: translate('page.privacy.header.title') }} />
                        <br />
                        <h2 dangerouslySetInnerHTML={{ __html: translate('page.privacy.header.date') }} />
                        <br />
                        <p dangerouslySetInnerHTML={{ __html: translate('page.privacy.content.greeting') }} />
                        <br />
                        {/* Sección de Recopilación de la Información */}
                        <h2 className="font-bold text-xl" dangerouslySetInnerHTML={{ __html: translate('page.privacy.content.info.title') }} />
                        <p dangerouslySetInnerHTML={{ __html: translate('page.privacy.content.info.terms') }} />
                        <br />

                        {/* Sección de Uso de la Información */}
                        <h2 className="font-bold text-xl" dangerouslySetInnerHTML={{ __html: translate('page.privacy.content.usage.title') }} />
                        <p dangerouslySetInnerHTML={{ __html: translate('page.privacy.content.usage.terms') }} />
                        <br />

                        {/* Sección de Protección de la Información */}
                        <h2 className="font-bold text-xl" dangerouslySetInnerHTML={{ __html: translate('page.privacy.content.protection.title') }} />
                        <p dangerouslySetInnerHTML={{ __html: translate('page.privacy.content.protection.terms') }} />
                        <br />

                        {/* Sección de Cookies */}
                        <h2 className="font-bold text-xl" dangerouslySetInnerHTML={{ __html: translate('page.privacy.content.cookies.title') }} />
                        <p dangerouslySetInnerHTML={{ __html: translate('page.privacy.content.cookies.terms') }} />
                        <br />

                        {/* Sección de Enlaces a Sitios Externos */}
                        <h2 className="font-bold text-xl" dangerouslySetInnerHTML={{ __html: translate('page.privacy.content.links.title') }} />
                        <p dangerouslySetInnerHTML={{ __html: translate('page.privacy.content.links.terms') }} />
                        <br />

                        {/* Sección de Cambios en la Política de Privacidad */}
                        <h2 className="font-bold text-xl" dangerouslySetInnerHTML={{ __html: translate('page.privacy.content.changes.title') }} />
                        <p dangerouslySetInnerHTML={{ __html: translate('page.privacy.content.changes.terms') }} />
                        <br />

                        {/* Sección de Contacto */}
                        <h2 className="font-bold text-xl" dangerouslySetInnerHTML={{ __html: translate('page.privacy.content.contact.title') }} />
                        <p dangerouslySetInnerHTML={{ __html: translate('page.privacy.content.contact.terms') }} />
                        <br />
                        <p dangerouslySetInnerHTML={{ __html: translate('page.privacy.footer.thanks') }} />
                        <br />
                        <p dangerouslySetInnerHTML={{ __html: translate('page.privacy.footer.lastUpdated') }} />

                    </Container>
                </Section>
            </div>
        );
    }

}