import React, { Component } from 'react';
import './index.css';
import { BotonTransparente } from '../botones';
import TextoTransicion from '../textTransition';
import { Link } from 'react-router-dom';
import SiteHeader from '../siteHeader';
import Container from '../container';
import { translate } from '../../util/i18n';

export default class LandingHeader extends Component {

    render() {
        return (
            <div
                className="bg-shark text-beige flex flex-col items-center justify-center h-[100vh] px-0 border-b border-beige/50"
                style={{
                    backgroundImage: `url("stock-navvi.webp")`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover'
                }}
            >
                <div 
                    // className="bg-shark/30 px-5 xl:px-0 flex flex-col items-center justify-center w-full h-full backdrop-grayscale-[.94] backdrop-brightness-[.85] backdrop-hue-rotate-[151deg] backdrop-saturate-[1] backdrop-contrast-[.72]"
                    className="px-5 xl:px-0 flex flex-col items-center justify-center w-full h-full"
                >
                    <Container className="border-x border-beige/50 flex flex-col items-center justify-center h-full">
                        {/* <img className='absolute top-0 max-w-30 mt-10' src='navvi-logo.png' alt='Logo'></img> */}
                        <div className="absolute top-0">
                            <SiteHeader />
                        </div>
                        <div className="text-3xl min-[320px]:text-[20px] min-[375px]:text-[27px] min-[376px]:text-[28px] sm:text-[48px] lg:text-[90px] text-center mb-5">
                            <TextoTransicion />
                            <h1 className="font-normal -mt-1 sm:mt-0 md:mt-2 lg:mt-10">{translate('page.home.header.title.continued')}</h1>
                        </div>
                        <p className="text-lg md:text-2xl text-center my-10">{translate('page.home.header.subtitle.text')}</p>
                        <Link to={translate('page.contact.path')}>
                            <BotonTransparente>
                                <p className="text-2xl font-normal">
                                    {translate('page.home.header.button')}
                                </p>
                            </BotonTransparente>
                        </Link>
                    </Container>
                </div>
            </div>

        );
    }

}