import React from 'react';
import "./index.css";
import TextTransition, { presets } from 'react-text-transition';
import { translate } from '../../util/i18n';

const servicios = ["page.home.header.title.title1", "page.home.header.title.title2", "page.home.header.title.title3"];

export default function TextoTransicion()  {
    const [index, setIndex] = React.useState(0);
  
    React.useEffect(() => {
      const intervalId = setInterval(
        () => setIndex((index) => index + 1),
        3000, // every 3 seconds
      );
      return () => clearTimeout(intervalId);
    }, []);
  
    return (
      <h1 className='text-cabaret font-bold'>
        <TextTransition springConfig={presets.slow} inline={false} style={{
          textAlign:"center"
        }}>{translate(servicios[index % servicios.length])}</TextTransition>
      </h1>
    );
  };