import React, { useRef, useEffect } from "react";
import Section from "../section";
import Container from "../container"
import "./index.css";
import { Link } from 'react-router-dom';
import { motion, useInView, useAnimation, useWillChange } from "framer-motion";
import { translate } from "../../util/i18n";
import { useMediaQuery } from "@uidotdev/usehooks";


export function NuestrosClientes(props) {
    const ref = useRef(null);
    const view = useInView(ref, { once: true });
    const maincontrols = useAnimation();
    const willChange = useWillChange();
    useEffect(() => {
        if (view) {
            //aqui se lanza la animacion si al hacer scroll el componente es visible
            maincontrols.start("visible");
        }
    });
    const isDesktop = useMediaQuery('(min-width: 1024px)');
    const variants = isDesktop ? 
    {
        rest: { opacity: 0, x: 75 },
        visible: {
            opacity: 1, x: -8
        }
    } 
    :{

    }
    const variants2 = isDesktop ? 
    {
        rest: { opacity: 0, y: 75 },
        visible: {
            opacity: 1, y: 0
        }
    } 
    :{

    }
    /*const back = {
        rest: {
            opacity: 0, zIndex: 100, borderRadius: '24px'
        },
        hover: {
            opacity: 1, scale: 1.1, x: -30, y:-20, borderRadius: '24px', blur: 'blur(5px)', backblur: 'backdrop-blur(5px)', backgroundcolor: 'bg-shark/70',
            transition: { duration: 0.3 }
        }
    }
    const back2 = {
        anima: {
            opacity: 1, y: 50
        },
        rest: {
            opacity: 0, y: 50, x:0
        },
        hover: {
            opacity: 1, y: 50, x:0,
           
        }

    }

    const back3 = {
        anima: {
            opacity: 1, y: 50
        },
        rest: {
            opacity: 0, y: 65, x:0
        },
        hover: {
            opacity: 1, y: 65, x:0,
            
        }

    }*/
    return (
        <div className="bg-beige">
            <Section className="px-5 xl:px-0">
                <Container className="border-l border-r border-shark h-16" />
            </Section>
            <Section>
                <Container>
                    <div className="grid place-content-center border-l border-shark border-r-[1px] h-full lg:pb-16">
                        <div className="flex flex-row lg:space-x-10 place-content-evenly ">
                            <div ref={ref} className="hidden sm:hidden md:hidden lg:flex lg:flex-col lg:items-center lg:basis-1/2 lg:visible lg:w-[435px]">
                                {/*aqui se aplica la animacion al componente de ir de derecha a izquierda*/}
                                <motion.div
                                    variants={variants}
                                    initial="rest"
                                    animate={maincontrols}
                                    transition={{ duration: 1, delay: 0.25, }}>
                                    {/*aqui se aplica la animacion a la foto de hacerse mas grande*/}

                                    <motion.div
                                        variants={{
                                            hidden: { opacity: 1 },
                                            visible: {
                                                opacity: 1
                                            }
                                        }}
                                        whileHover={{ opacity: 1, scale: 1.1, }}
                                        style={{ cursor: "pointer", willChange: willChange }}
                                        className="p-0 w-[352px] h-[235px] justify-center items-center"
                                    >
                                        <div className="p-0 w-[352px] h-[235px] justify-center items-center mt-6 xl:w-[390px] xl:h-[280px]" style={{ backgroundImage: `url("our-clients-drp.png")`, objectFit:"initial" , borderRadius: '1.5rem', backgroundSize: 'cover', backgroundPosition: 'center', aspectRatio:'16 / 9' }}>
                                            <Link to={translate('page.portfolio.path')}>
                                                <div style={{ cursor: "pointer" }} className="flex-col opacity-0 h-full w-full hover:opacity-100 hover:relative hover:inset-x-0 hover:bottom-0 hover:flex hover:justify-center hover:items-center hover:h-full hover:backdrop-blur-sm hover:text-xl hover:transition hover:bg-shark/70 hover:text-center hover:px-4 hover:rounded-3xl">
                                                    <h3 className="font-bold text-2xl">{translate("page.portfolio.patricia.title")}</h3>
                                                    <p className="font-thin italic">{translate("general.project.type.web").toLowerCase()} + {translate("general.project.type.seo").toLowerCase()} + {translate("general.project.type.uxui").toLowerCase()}</p>
                                                    <h3 className="text-lg">{translate("page.portfolio.patricia.year")}</h3>
                                                    <p className="text-lg underline">{translate("component.button.viewPortfolio.label")}</p>
                                                </div>
                                            </Link>
                                        </div>
                                    </motion.div>

                                    {/*aqui se aplica la animacion a la foto de hacerse mas grande*/}
                                    {/* <motion.div
                                        variants={{
                                            hidden: { opacity: 1 },
                                            visible: {
                                                opacity: 1
                                            }
                                        }}
                                        whileHover={{ opacity: 1, scale: 1.1, }}
                                        style={{ cursor: "pointer", willChange: willChange }}
                                        className="p-0 w-[352px] h-[235px] justify-center items-center"
                                    >
                                        <div className="p-0 w-[352px] h-[235px] justify-center items-center mt-6" style={{ backgroundImage: `url("stock-navvi.webp")`, backgroundSize: '352px 235px', borderRadius: '30px', backgroundPosition: 'center' }}>
                                            <Link to={translate('page.portfolio.path')}>
                                                <div style={{ cursor: "pointer" }} className="opacity-0 h-full w-full hover:opacity-100 hover:relative hover:inset-x-0 hover:bottom-0 hover:flex hover:justify-center hover:items-center hover:h-full hover:backdrop-blur-sm hover:text-xl hover:transition hover:bg-shark/70 hover:text-center hover:px-4 hover:rounded-3xl">
                                                    sitio del cliente
                                                </div>
                                            </Link>
                                        </div>
                                    </motion.div> */}
                                </motion.div>
                            </div>


                            <motion.div
                                variants={variants2}
                                initial="rest"
                                animate={maincontrols}
                                transition={{ duration: 1, delay: 0.25, }}
                                className="lg:basis-1/2">

                                <div className="flex  flex-col items-center pb-4 h-fit w-[315px] sm:items-center -z-10 sm:w-full sm:pb-14 lg:h-fit lg:pb-4 lg:block ">
                                    <div className="flex flex-col items-center w-[280px] sm:w-[345px] p-2 text-center justify-center xl:w-[390px] ">
                                        <p className="pb-2 font-bold text-shark text-xl mobil:text-xl tracking-[3px] text-center xl:text-3xl" dangerouslySetInnerHTML={{__html: translate('page.home.customers.title')}}/>
                                        <p className="text-shark text-md mobil:text-md text-center w-[280px] xl:text-lg">{translate('page.home.customers.subtitle')}</p>
                                        <Link to={translate('page.portfolio.path')}>
                                            <VerMasTrabajos />
                                        </Link>
                                    </div>

                                    <motion.div 
                                        variants={{
                                            hidden: { opacity: 1 },
                                            visible: {
                                                opacity: 1
                                            }
                                        }}
                                        initial="hidden"
                                        whileHover={{ opacity: 1, scale: 1.1 }}
                                        style={{ cursor: "pointer", willChange: willChange }}
                                        className="p-0 w-[280px] h-[235px]  mobil:h-[235px] sm:w-[352px] sm:h-[235px] justify-center items-center "
                                    >
                                        <div className="flex-col p-0 w-[280px] h-[200px]  mobil:h-[200px] mt-2 sm:w-[352px] sm:h-[235px] justify-center items-center sm:mt-0 aspect-video xl:w-[390px] xl:h-[280px]" style={{ backgroundImage: `url("our-clients-celalvasa.png")`, borderRadius: '1.5rem' , objectFit: 'cover', backgroundRepeat: "no-repeat", backgroundSize: 'cover', backgroundPosition: 'center', aspectRatio:'16 / 9' }}>
                                            <Link to={translate('page.portfolio.path')} rel="noopener noreferrer">
                                                <div style={{ cursor: "pointer" }} className="flex-col opacity-0 h-full w-full hover:opacity-100 hover:relative hover:inset-x-0 hover:bottom-0 hover:flex hover:justify-center hover:items-center hover:h-full hover:backdrop-blur-sm hover:text-xl hover:transition hover:bg-shark/70 hover:text-center hover:px-4 hover:rounded-3xl">
                                                    <h3 className="font-bold text-2xl">{translate("page.portfolio.cealva.title")}</h3>
                                                    <p className="font-thin italic">{translate("general.project.type.web").toLowerCase()} + {translate("general.project.type.seo").toLowerCase()} + {translate("general.project.type.uxui").toLowerCase()}</p>
                                                    <h3 className="text-lg">{translate("page.portfolio.cealva.year")}</h3>
                                                    <p className="text-lg underline">{translate("component.button.viewPortfolio.label")}</p>
                                                </div>
                                            </Link>
                                        </div>
                                    </motion.div>
                                    <motion.div
                                        variants={{
                                            hidden: { opacity: 1 },
                                            visible: {
                                                opacity: 1
                                            }
                                        }}
                                        whileHover={{ opacity: 1, scale: 1.1 }}
                                        style={{ cursor: "pointer", willChange: willChange }}
                                        className="lg:hidden p-0 w-[280px] h-[235px] sm:w-[352px] sm:h-[235px] justify-center items-center rounded-3xl md:visible"
                                    >
                                        <div className="p-0 w-[280px] h-[200px] mobil:h-[200px] mt-0 sm:w-[352px] sm:h-[235px] justify-center items-center sm:mt-6 aspect-video" style={{ backgroundImage: `url("our-clients-drp.png")`,borderRadius: '1.5rem' , objectFit: 'cover', backgroundRepeat: "no-repeat", backgroundSize: 'cover', backgroundPosition: 'center' , aspectRatio:'16 / 9'}}>
                                            <Link to={translate('page.portfolio.path')} rel="noopener noreferrer">
                                                <div style={{ cursor: "pointer" }} className="flex-col opacity-0 h-full w-full hover:opacity-100 hover:relative hover:inset-x-0 hover:bottom-0 hover:flex hover:justify-center hover:items-center hover:h-full hover:backdrop-blur-sm hover:text-xl hover:transition hover:bg-shark/70 hover:text-center hover:px-4 hover:rounded-3xl">
                                                    <h3 className="font-bold text-2xl">{translate("page.portfolio.patricia.title")}</h3>
                                                    <p className="font-thin italic">{translate("general.project.type.web").toLowerCase()} + {translate("general.project.type.seo").toLowerCase()} + {translate("general.project.type.uxui").toLowerCase()}</p>
                                                    <p className="text-lg underline">{translate("component.button.viewPortfolio.label")}</p>
                                                </div>
                                            </Link>
                                        </div>
                                    </motion.div>   
                                </div>
                            </motion.div>

                        </div>
                    </div>
                </Container>
            </Section>
            <Section className="px-5 xl:px-0">
                <Container className="border-l border-r border-shark h-16" />
            </Section>
        </div>
    );
}




export function VerMasTrabajos(props) {

    return (
        <button className="btn border-solid border border-shark px-4 lg:px-8 lg:py-1 m-3 rounded-3xl text-xl text-shark hover:bg-shark/50 hover:text-beige hover:border-beige">
            {translate('component.button.viewMore.label')}
        </button>
    );

}