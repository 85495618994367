// import ReactPixel from 'react-facebook-pixel';

const gtag = window.gtag;

// const PIXEL_ID = 'yourPixelIdGoesHere';

export const initialize = () => {
    // ReactPixel.init(PIXEL_ID, advancedMatching, options);
}

export const registerConversion = (page, projectType) => {
    // console.log(gtag);
    gtag('event', 'contact_form_submit', {
        'event_category': 'Form',
        'event_label': 'Contact Form',
        'project_type': projectType,
    });

    // ReactPixel.trackSingleCustom(PIXEL_ID, event, data); // For tracking custom events.

}