import { Component } from 'react';
import { translate } from '../../util/i18n';
import { registerConversion } from '../../util/analytics';

const categories = [
    "web",
    "mobile",
    "dev",
    "seo",
    "apps",
    "uxui"
];

export default class ContactSection extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modal: false,
        };
    }

    handleForm = async (e) => {
        e.preventDefault();

        // Validate form
        var form = document.getElementById("contact-form");
        var valid = true;
        for (var i = 0; i < form.length; i++) {
            console.log(`'${form[i].id}'`, `'${form[i].value}'`);
            if (form[i].id !== "" && form[i].value === "") {
                form[i].classList.add("border-red-400");
                valid = false;
            }
        }
        if (document.getElementById("projectType").value === "") {
            document.getElementById("projectType").classList.add("border-red-400");
            valid = false;
        }
        if (!valid) {
            console.log("Not valid form")
            return;
        }

        let projectType = document.getElementById("projectType").value;

        // console.log("Formulario enviado");
        await fetch("https://navvi-contact.jorgeberrezuetav.workers.dev/", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                email: document.getElementById("email").value,
                projectType: translate('general.project.type.' + projectType),
                subject: document.getElementById("subject").value,
                description: document.getElementById("desc").value
            })
        });
        // Clear fields
        document.getElementById("email").value = "";
        document.getElementById("subject").value = "";
        document.getElementById("desc").value = "";
        // Show modal
        this.setState({
            modal: true
        });
        registerConversion("contact", projectType);
    }

    onFocusAnyInput = (e) => {
        // dismiss class border-red-400
        e.target.classList.remove("border-red-400");
    }

    handleModalDismiss = () => {
        this.setState({
            modal: false
        });
    }

    render() {
        var mode = this.props.mode || "dark";
        var style = {
            border: mode === "dark" ? "border-beige/50" : "border-shark",
            bg: mode === "dark" ? "bg-shark" : "bg-beige",
            text: mode === "dark" ? "text-beige" : "text-shark"
        }
        // const subtitle = <p>Contáctate con <span className="text-marino">nosotros</span>, envíanos tu <span className="text-cabaret">idea</span> en conjunto con un pequeño brief y juntos lo haremos una <span className="text-marino">realidad</span></p>;
        const subtitle = (<p dangerouslySetInnerHTML={{__html: translate('component.contact.subtitle')}}></p>);
        return (
            <section className={"flex justify-center items-center " + style.bg + " " + style.text + " " + this.props.className}>
                <div className={"px-5 xl:px-0 w-full max-w-[1510px] xl:w-[70%] lg:min-w-[600px] flex flex-col justify-center " + this.props.className}>
                    <div>
                        <div className="flex flex-row justify-center items-stretch">
                            <div className={"grow border-l " + style.border} />
                            <div className="py-5 md:py-10">
                                <h1 className={"font-bold text-xl tracking-[3px] " + style.text}>{translate('component.contact.title')}</h1>
                            </div>
                            <div className="grow">
                                <div className={"border-r border-b rounded-br-3xl h-1/2 " + style.border} />
                            </div>
                        </div>
                        <div className="lg:hidden flex flex-row justify-center items-stretch">
                            <div className={"grow border-l " + style.border} />
                            <div className="pb-5 md:pb-10 lg:py-10">
                                <div className="lg:hidden text-xl p-5 text-center">
                                    { subtitle }
                                </div>
                            </div>
                            <div className="grow">
                                <div className="h-1/2" />
                            </div>
                        </div>
                        <div className="flex flex-row items-stretch justify-center">
                            <div className="basis-1/12 lg:basis-1/3">
                                <div className={"border-l border-b rounded-bl-3xl h-16 " + style.border}></div>
                                <div className="hidden lg:inline col-start-1 row-span-4">
                                    <div className="text-xl px-16 pt-10">
                                    { subtitle }
                                    </div>
                                </div>
                            </div>
                            <div className="grow">
                                <div className={"border rounded-3xl mt-5 " + style.border}>
                                    <div className="p-5">
                                        <form id="contact-form" className="flex flex-col space-y-3">
                                            <select name="projectType" id="projectType" onFocus={this.onFocusAnyInput} form="contact" placeholder={translate('component.contact.projectType.label')} defaultValue="" className={"border rounded-full py-1 px-2 " + style.bg + " " + style.border + " " + style.text}>
                                                <option value="" disabled>{translate('component.contact.projectType.label')}</option>
                                                {categories.map((category, index) => {
                                                    return (
                                                        <option key={index} value={category}>{translate('general.project.type.' + category)}</option>
                                                    )
                                                })
                                                }
                                            </select>
                                            <input type="email" name="email" id="email" onFocus={this.onFocusAnyInput} placeholder={translate('component.contact.email.label')} className={"transition border rounded-full py-1 px-2 " + style.bg + " " + style.border} />
                                            <input type="subject" name="subject" id="subject" onFocus={this.onFocusAnyInput} placeholder={translate('component.contact.subject.label')} className={"transition border rounded-full py-1 px-2 " + style.bg + " " + style.border} />
                                            <textarea name="desc" id="desc" onFocus={this.onFocusAnyInput} placeholder={translate('component.contact.description.label')} className={"transition border rounded-2xl py-1 px-2 h-[100px] " + style.bg + " " + style.border} />
                                            <button onClick={this.handleForm} className="bg-marino text-beige rounded-full py-1 px-2 hover:bg-marino-600 transition">{translate('component.contact.submit.label')}</button>
                                        </form>
                                    </div>
                                </div>
                                <div className="col-start-2 flex flex-col items-center justify-center h-10">
                                    <div className={"border-l h-full " + style.border} />
                                    <div className={"rotate-45 border-b border-r -translate-y-1 w-[5px] h-[6px] " + style.border} />
                                </div>
                            </div>
                            <div className="basis-1/12 lg:basis-1/3" />
                        </div>
                    </div>
                </div>
                {this.state.modal &&
                    <div className="fixed h-[100vh] w-[100vw] backdrop-blur bg-shark/60 z-30 top-0 left-0 flex justify-center items-center transition-all" onClick={this.handleModalDismiss}>
                        <div className="bg-shark text-beige border border-beige/50 rounded-3xl p-5 text-center max-w-96 flex flex-col gap-10" onClick={() => {}}>
                            <div className='text-2xl font-bold text-marino'>
                                <h1>{translate('component.contact.modal.received.title')}</h1>
                            </div>
                            <div className="text-lg">
                                <h2 dangerouslySetInnerHTML={{__html: translate('component.contact.modal.received.content')}}/>
                            </div>
                            <div>
                                <button 
                                    onClick={this.handleModalDismiss}
                                    className="bg-marino w-full py-1 rounded-full hover:bg-marino-600 transition-color outline outline-0 hover:outline-offset-2 active:outline-2 active:outline-marino hover:outline-2"
                                >
                                    {translate('component.contact.modal.received.done')}
                                </button>
                            </div>
                        </div>
                    </div>
                }
            </section>
        );
    }
}