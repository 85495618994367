import { Component } from 'react';
import { Link } from 'react-router-dom';

export default class SiteHeader extends Component {

    render() {
        return (
            <div className="py-4 w-full flex justify-center">
                <Link to="/">
                    <img className="w-[217px]" src="navvi_dark.svg" alt="navvi"></img>
                </Link>
            </div>
        )
    }

}