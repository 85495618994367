import { Component } from 'react';

export default class Container extends Component {

    render() {
        return (
            <div className={"px-5 xl:px-0 w-full max-w-[1510px] xl:w-[70%] lg:min-w-[600px] flex flex-col justify-center " + (this.props.className || "")}>
                {this.props.children}
            </div>
        );
    }

}
