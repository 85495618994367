import React, { Component } from 'react';
import './site.css';
import { Outlet } from 'react-router-dom';
import StickyNavbar from '../components/stickyNavbar';
import SiteFooter from '../components/siteFooter';

export default class SitePage extends Component {

    render() {
        return (
            <div className="main-container">
                <div className="content-container">
                    <StickyNavbar navigate={this.props.navigate} location={this.props.location} />
                    <div className="transition-all">
                        <Outlet />
                    </div>
                    <SiteFooter />
                </div>
            </div>
        );
    }

}