import { Link } from 'react-router-dom';

const InstagramFeed = (props) => {
    if(props.loading) return <>
        <div className="bg-shark-800 aspect-square animate-pulse"></div>
        <div className="bg-shark-800 aspect-square animate-pulse"></div>
        <div className="bg-shark-800 aspect-square animate-pulse"></div>
    </>;
    if(props.posts.length === 0){
        return(
            <div className="relative group">
                <p>no hay publicaciones por el momento</p>
            </div>
        )
    }
    return props.posts.map(post => {
        var IMAGE_URL = post.media_type === "VIDEO" ? post.thumbnail_url : post.media_url;
        return(
            <div key={post.id} className="relative group">
                <div className="bg-contain bg-center bg-no-repeat w-full h-full border-[3px] rounded-md border-beige/30" style={{backgroundImage: `url(${IMAGE_URL})`}}></div>
                <Link to={post.permalink} target="_blank" rel="noopener noreferrer">
                    <div style={{cursor:"pointer"}} className="opacity-0 group-hover:opacity-100 absolute inset-x-0 bottom-0 flex justify-center items-center h-full backdrop-blur-sm text-xl transition bg-shark/70 text-center px-4">ir a la publicación de instagram</div>
                </Link>
            </div>
        )
    })
}

export default InstagramFeed;