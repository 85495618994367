// import logo from './logo.svg';
import './App.css';
import LandingPage from './routes/landing';
import { isSupportedLang, storeCurrentLang, translate } from './util/i18n';
import { Helmet } from 'react-helmet';

import {
    createBrowserRouter,
    RouterProvider,
    useLocation,
    useNavigate,
    useSearchParams,
} from "react-router-dom";
import SitePage from './routes/site';
import TeamPage from './routes/team';
import PortfolioPage from './routes/portfolio';
import ContactPage from './routes/contact';
import { useEffect } from 'react';
import PrivacyPolicyPage from './routes/privacy';

function NavigatableSite() {
    const [searchParams] = useSearchParams();
    let lang = searchParams.get("l");
    if (isSupportedLang(lang)) {
        storeCurrentLang(lang);
    }
    let navigate = useNavigate();
    let location = useLocation();
    return (
        <>
            <Helmet>
                <meta name="keywords" content={translate('seo.meta.keywords')}/>
                <meta property="title" content={translate('page.home.title')} />
                <meta property="description" content={translate('seo.meta.description')} />
                <meta property="og:title" content={translate('page.home.title')} />
                <meta property="og:description" content={translate('seo.meta.description')} />
            </Helmet>
            <SitePage navigate={navigate} location={location} />
        </>
    );
}

function Redirect() {
    let navigate = useNavigate();
    useEffect(() => {
        navigate("/");
    });
    return null;
}

const router = createBrowserRouter([
    {
        path: "/",
        element: <NavigatableSite />,
        errorElement: <Redirect />,
        children: [
            {
                index: true,
                path: translate('page.home.path'),
                element: <LandingPage />,
            },
            {
                path: translate('page.team.path'),
                element: <TeamPage />,
            },
            {
                path: translate('page.portfolio.path'),
                element: <PortfolioPage />,
            },
            {
                path: translate('page.contact.path'),
                element: <ContactPage />,
            },
            {
                path: translate('page.privacy.path'),
                element: <PrivacyPolicyPage />,
            }
        ],
    }
]);

function App() {
    return (
        <div className="App">
            <RouterProvider router={router} />
        </div>
    );
}

export default App;
