import React, { Component } from 'react';
import './index.css';

export class Boton extends Component {
    render() {
        const onClick = this.props.onClick || (() => {});
        return (
            <button 
                className="boton" 
                style={{ backgroundColor: this.props.color }} 
                onClick={onClick}
            >
                {this.props.children}
            </button>
        );
    }
}

export class BotonPrimario extends Component {
    render() {
        return (
            <Boton color="#FF0000" onClick={this.props.onClick}>
                {this.props.children}
            </Boton>
        );
    }
}

export class BotonSecundario extends Component {
    render() {
        return (
            <Boton color="#00FF00" onClick={this.props.onClick}>
                {this.props.children}
            </Boton>
        );
    }
}

export class BotonTransparente extends Component {
    render() {
        return (
            <button className="hover:bg-beige/50 bg-transparent text-beige border border-white rounded-full px-5 py-2" onClick={this.props.onClick}>
                {this.props.children}
            </button>
        );
    }
}