import { Component } from "react"
import { Helmet } from "react-helmet"
import { translate } from "../../util/i18n"



export default class MetadataHelmet extends Component {

    render() {
        if (!this.props.pageName) return null;
        console.log(`title: ${translate(`page.${this.props.pageName}.title`)} keywords: ${translate(`seo.meta.${this.props.pageName}.keywords`)}`);
        return (
            <Helmet>
                <title>{translate(`page.${this.props.pageName}.title`)}</title>
                <meta name="title" content={translate(`page.${this.props.pageName}.title`)}></meta>
                <meta property="og:title" content={translate(`page.${this.props.pageName}.title`)}></meta>
                <meta name="description" content={translate(`seo.meta.${this.props.pageName}.description`)} />
                <meta property="og:description" content={translate(`seo.meta.${this.props.pageName}.description`)}></meta>
                <meta name="keywords" content={translate(`seo.meta.${this.props.pageName}.keywords`)}></meta>
            </Helmet>
        );
    }

}