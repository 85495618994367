import React, { Component } from 'react';
import LandingHeader from '../../components/landingHeader';
import {Texto} from '../../components/Elegir/index'
import ContactSection from '../../components/contactSection';
import {NuestrosClientes} from '../../components/nuestrosClientes';
import MetadataHelmet from '../../components/metadataHelmet';

export default class LandingPage extends Component {
    
    render() {
        return (
            <div>
                <MetadataHelmet pageName="home" />
                <LandingHeader />
                <Texto/>
                <NuestrosClientes />
                <ContactSection mode='dark' />
            </div>
        );
    }
    
}