import { Component } from 'react';

export default class Section extends Component {

    render() {
        return (
            <section className={"flex justify-center items-center " + (this.props.className || "")}>
                {this.props.children}
            </section>
        );
    }

}